<template>
  <div class="section-wrapper">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{$t('tradeTariffConfig.dashboard')}}</h4>
      </template>
      <template v-slot:searchBody>
        <b-row>
        <b-col sm="4">
          <b-form-select
              v-model="search.fiscal_year_id"
              :options="fiscalYearList"
              size="sm">
              <template v-slot:first>
                  <b-form-select-option :value="0">{{$t('eBizProgram.filter_by_fiscal_year')}}</b-form-select-option>
              </template>
          </b-form-select>
        </b-col>
        </b-row>
      </template>
      <!-- search section end -->
    </card>
    <b-overlay :show="loader">
      <b-card class="trade_fire_wrapper">
        <b-row>
          <b-col class="bdr" sm="4">
            <div class="info-card upcoming">
              <div class="content">
                <p>{{$t('tradeTariffConfig.total_application')}}</p>
                <h3></h3>
              </div>
              <img src="../../../../assets/images/total-application.png" class="img-fluid" alt="">
            </div>
          </b-col>
          <b-col class="bdr mt-3 mt-sm-0" sm="4">
            <div class="info-card total">
              <div class="content">
                <p>{{$t('tradeTariffConfig.approve_application')}}</p>
                <h3></h3>
              </div>
              <img src="../../../../assets/images/folder-bro.png" class="img-fluid" alt="">
            </div>
          </b-col>
          <b-col class="bdr" sm="4">
            <div class="info-card atended">
              <div class="content">
                <p>{{$t('tradeTariffConfig.total_consultancy')}}</p>
                <h3></h3>
              </div>
              <img src="../../../../assets/images/consultation.png" class="img-fluid" alt="">
            </div>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
  </div>
</template>
<script>
// import RestApi, { lrcpnServiceBaseUrl } from '@/config/api_config'
// import { tradeTariffDasboardRoute } from '../api/routes'
export default {
    data () {
        return {
            selected: null,
            search: {
              fiscal_year_id: 0,
              form_date: '',
              to_date: ''
            },
            dashData: {},
            associationList: [],
            show: false,
            loader: false
        }
    },
     watch: {
      'search.fiscal_year_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          const fiscalYear = this.fiscalYearList.find(item => item.value === newVal)
          this.search.form_date = fiscalYear.start_date
          this.search.to_date = fiscalYear.end_date
          this.searchData()
        }
      }
    },
    created () {
      // this.searchData()
    },
    computed: {
        fiscalYearList () {
          return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
        }
    },
    methods: {
      async searchData () {
        // this.loadData = true
        // this.loader = true
        // let result = null
        // const params = Object.assign({}, this.search)
        // result = await RestApi.getData(lrcpnServiceBaseUrl, tradeTariffDasboardRoute, params)
        // if (result.success) {
        //     this.dashData = result.data
        //     this.loadData = false
        //     this.show = true
        //     this.loader = false
        // } else {
        //     this.loadData = false
        //     this.loader = false
        // }
      }
  }
}
</script>
<style scoped>
.trade_fire_wrapper .info-card {
  border-radius: 1rem;
  color: #fff;
  padding: 1rem;
  min-height: 130px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.trade_fire_wrapper .info-card .content p {
  font-size: 20px;
}

.trade_fire_wrapper .info-card.upcoming {
  background: linear-gradient(90deg, #48c8a4 0%, #5CD6B3 100%);
}

.trade_fire_wrapper .info-card.total {
  color: rgb(24, 18, 1);
  background: linear-gradient(90deg, #fcf05a 0%, #fdbd40 100%);
}
.trade_fire_wrapper .info-card.atended {
  background: linear-gradient(90deg, #177be7 0%, rgb(39 76 213) 100%);
}

.trade_fire_wrapper .info-card.remaining {
  background: linear-gradient(90deg, rgb(63, 179, 98) 0%, rgb(134, 227, 162) 100%);
}

.trade_fire_wrapper .info-card img {
  height: 70px;
}
.upcoming-fair{
  background: url(../../../../assets/images/fair-card-bg.jpg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover !important;
  position: relative;
}
.upcoming-fair .icon img{
  width: 100px;
}
.upcoming-fair .content{
  padding: 2rem;
  width: 100%;
  color: #fff;
  text-align: center;
}
.trade_fire_participant .info-card {
  display: flex;
  align-items: center;
  color: #fff;
  padding: 1rem;
  border-radius: 1rem;
}
.trade_fire_participant .info-card.applicant{
  background: #48B5E4;
}
.trade_fire_participant .info-card.approved{
  background: #21C228;
}
.trade_fire_participant .info-card.peinding{
  background: #C96BE5;
}
.trade_fire_participant .info-card.reject{
  background: #F8576A;
}
.trade_fire_participant .info-card.paid{
  background: #6349F8;
}
.trade_fire_participant .info-card.unpaid{
  background: #E79B55;
}
.trade_fire_participant .info-card .atended{
  background: #55A6F8;
}
.trade_fire_participant .info-card .icon{
  font-size: 1.5rem;
  margin-right: .6rem;
}
</style>
